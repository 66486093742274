import { useState } from 'react';

import Link from 'next/link';
import { Drawer, ListItemText, MenuItem } from '@src/ui/material-ui';
import TrustedLogo from '@public/static/trusted_logomark_mint_black.svg';
import { Button, FontAwesomeIcon } from '@src/ui';
import * as colors from '@src/support/colors';
import {
  CollapsibleContent,
  CollapsibleSection,
} from '@src/ui/collapsible-section';
import type { JobPageNavigation } from '@src/queries/jobs-page-content-query';

const LinkButton = ({ name, href }: { name: string; href?: string }) => (
  <Button
    component="a"
    color="white"
    shape="rounded"
    size="large"
    style={{ paddingLeft: 0 }}
    href={href}
  >
    {name}
  </Button>
);

export const MobileMenu = ({
  drawerOpen,
  onClose,
  pageNavigation = [],
}: {
  drawerOpen: boolean;
  onClose: () => void;
  pageNavigation: JobPageNavigation[];
}) => {
  const [toggleOpen, setToggleOpen] = useState<string | undefined>();

  return (
    <>
      <Drawer anchor="left" open={drawerOpen} onClose={onClose}>
        <div className="DrawerContent">
          <div className="DrawerHeader">
            <TrustedLogo />
            <FontAwesomeIcon
              onClick={onClose}
              icon={['fas', 'times']}
              size="lg"
            />
          </div>
          <div className="DrawerBody">
            {[...pageNavigation]
              .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
              .map(({ name, link, subNavigations, isParent }) => {
                if (isParent && subNavigations && subNavigations.length > 0) {
                  return (
                    <div
                      key={`${name}_is_parent_${link}_is_parent_${isParent}`}
                    >
                      <div
                        onClick={() =>
                          setToggleOpen(toggleOpen === name ? undefined : name)
                        }
                        className="DrawerBodyItem"
                      >
                        <LinkButton name={name} />
                        <div className="DraweBodyItemIcon">
                          {toggleOpen === name ? (
                            <FontAwesomeIcon
                              icon={['fas', 'angle-up']}
                              size="1x"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={['fas', 'angle-down']}
                              size="1x"
                            />
                          )}
                        </div>
                      </div>

                      <CollapsibleSection
                        onToggle={() => setToggleOpen(undefined)}
                        open={toggleOpen === name}
                        shadow={false}
                      >
                        <CollapsibleContent>
                          {subNavigations.map(({ name, link }) => {
                            return (
                              <MenuItem key={`mobile_${name}_${link}`}>
                                <ListItemText>
                                  <Link href={link} legacyBehavior>
                                    {name}
                                  </Link>
                                </ListItemText>
                              </MenuItem>
                            );
                          })}
                        </CollapsibleContent>
                      </CollapsibleSection>
                    </div>
                  );
                }

                if (isParent) {
                  return (
                    <div
                      className="JobsPageHeaderItem"
                      key={`${name}_is_parent_mobile_${link}`}
                    >
                      <Link href={link} passHref legacyBehavior>
                        <LinkButton name={name} />
                      </Link>
                    </div>
                  );
                }
              })}
          </div>
          <div className="DrawerMobileApp">
            <p className="DrawerMobileAppDescription">
              Find, save and apply for jobs <br />
              faster in the mobile app
            </p>
            <div className="DrawerMobileAppButton">
              <Link
                href="https://itunes.apple.com/us/app/trusted-health/id1438467119?mt=8"
                passHref
                legacyBehavior
              >
                <Button
                  component="a"
                  size="medium"
                  style={{
                    backgroundColor: colors.black,
                    padding: '0.625em',
                    borderRadius: '0.625em',
                  }}
                >
                  <FontAwesomeIcon
                    color={colors.white}
                    icon={['fab', 'apple']}
                    size="3x"
                  />
                  <div>
                    <p className="DrawerAppleMobileButton">Download on the</p>
                    <p className="DrawerAppleMobileButtonAppStore">App Store</p>
                  </div>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Drawer>
      <style jsx>
        {`
          .DrawerContent {
            min-width: 18.75em;
            padding: 1.875em;
          }

          .DrawerHeader {
            display: flex;
            justify-content: space-between;
          }

          .DrawerBody {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            margin-top: 4.375em;
          }

          .DrawerBodyItem {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .DraweBodyItemIcon {
            display: flex;
          }

          .DrawerSignUpButton,
          .DrawerMobileApp {
            margin-top: 2.5em;
          }

          .DrawerMobileApp {
            background-color: ${colors.neutralLower};
            border-radius: 0.625em;
            font-size: 1rem;
            min-height: 9.375em;
            min-width: 15.375em;
            padding: 1.25em;
          }

          .DrawerMobileAppButton {
            margin-top: 1.125em;
          }

          .DrawerMobileAppDescription {
            font-weight: bold;
          }

          .DrawerAppleMobileButton,
          .DrawerAppleMobileButtonAppStore {
            color: ${colors.white};
            font-weight: 500;
            padding-left: 0.3125em;
          }

          .DrawerAppleMobileButtonAppStore {
            font-size: 1.25em;
            text-align: left;
          }
        `}
      </style>
    </>
  );
};
