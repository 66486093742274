import { MOBILE_MAX_WIDTH } from '@src/constants';
import clsx from 'clsx';

import type { PropsWithChildren } from 'react';

type TextProps = PropsWithChildren<{
  className?: string;
}>;

type HeaderProps = TextProps & {
  size: 1 | 2 | 3 | 4 | 5 | 6;
};

export const DSHeader = ({ children, className, size }: HeaderProps) => {
  const HeadingElement: keyof JSX.IntrinsicElements = `h${size}`;
  return (
    <>
      <HeadingElement
        className={clsx('ds-header', `ds-header--size-${size}`, className)}
      >
        {children}
      </HeadingElement>
      <style jsx>{`
        .ds-header {
          font-family: 'Grosa';
        }
        .ds-header--size-1 {
          font-size: 3rem;
          line-height: 4rem;
          font-weight: 800;
        }
        .ds-header--size-2 {
          font-size: 2rem;
          line-height: 2.5rem;
          font-weight: 800;
        }
        .ds-header--size-3 {
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 800;
        }
        .ds-header--size-4 {
          font-size: 1.25rem;
          line-height: 1.5rem;
          font-weight: 800;
        }
        .ds-header--size-5 {
          font-size: 1.125rem;
          line-height: 1.25rem;
          font-weight: 800;
        }
        .ds-header--size-6 {
          font-size: 1rem;
          line-height: 1.125rem;
          font-weight: bold;
        }
        @media (max-width: ${MOBILE_MAX_WIDTH}) {
          .ds-header--size-1 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 800;
          }
          .ds-header--size-2 {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 800;
          }
          .ds-header--size-3 {
            font-size: 1rem;
            line-height: 1.25rem;
            font-weight: 800;
          }
          .ds-header--size-4 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 800;
          }
          .ds-header--size-5 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 800;
          }
          .ds-header--size-6 {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: bold;
          }
        }
      `}</style>
    </>
  );
};

type BodyTextProps = TextProps & {
  small?: boolean;
  bold?: boolean;
  uppercase?: boolean;
  spaced?: boolean;
  big?: boolean;
  underline?: boolean;
};
export const DSBodyText = ({
  children,
  small,
  bold,
  uppercase,
  spaced,
  big,
  underline,
  className,
}: BodyTextProps) => (
  <span
    className={clsx(
      'ds-body-text',
      {
        'ds-body-text--bold': bold,
        'ds-body-text--small': small,
        'ds-body-text--uppercase': uppercase,
        'ds-body-text--spaced': spaced,
        'ds-body-text--big': big,
        'ds-body-text--underline': underline,
      },
      className
    )}
  >
    {children}
    <style jsx>{`
      .ds-body-text {
        font-family: 'Grosa';
        font-size: 1rem;
        line-height: 1.25rem;
      }
      .ds-body-text--bold {
        font-weight: bold;
      }
      .ds-body-text--small {
        font-size: 0.875rem;
      }
      .ds-body-text--big {
        font-size: 1.25rem;
        line-height: 1.625rem;
      }
      .ds-body-text--uppercase {
        text-transform: uppercase;
      }
      .ds-body-text--spaced {
        letter-spacing: 0.08em;
      }
      .ds-body-text--underline {
        text-decoration-line: underline;
      }
    `}</style>
  </span>
);

export const DSCaptionText = ({ children, className }: TextProps) => (
  <span className={clsx('ds-caption-text', className)}>
    {children}
    <style jsx>{`
      .ds-caption-text {
        font-family: 'Grosa';
        font-size: 0.75rem;
        line-height: 1rem;
      }
    `}</style>
  </span>
);

type OverlineProps = TextProps & {
  bold?: boolean;
};
export const DSOverline = ({ children, className, bold }: OverlineProps) => (
  <span
    className={clsx('ds-overline', className, {
      'ds-overline--bold': bold,
    })}
  >
    {children}
    <style jsx>{`
      .ds-overline {
        font-family: 'Grosa';
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      .ds-overline--bold {
        font-weight: bold;
      }
    `}</style>
  </span>
);

type DisplayProps = TextProps & {
  size: 1 | 2 | 3 | 4 | 5;
};

export const DSDisplay = ({ children, size, className }: DisplayProps) => (
  <span className={clsx('ds-display', `ds-display--size-${size}`, className)}>
    {children}
    <style jsx>{`
      .ds-display {
        font-family: 'Tiempos Text';
      }
      .ds-display--size-1 {
        font-size: 3.5rem;
        line-height: 4.5rem;
      }
      .ds-display--size-2 {
        font-size: 3rem;
        line-height: 4rem;
      }
      .ds-display--size-3 {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
      .ds-display--size-4 {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      .ds-display--size-5 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    `}</style>
  </span>
);
