export enum JobPageType {
  Nursing = 'nursing',
  Allied = 'allied',
  All = 'all',
}

export const JOB_PAGE_TYPES = {
  'travel-nurse': JobPageType.Nursing,
  'travel-allied': JobPageType.Allied,
};
