import { FontAwesomeIcon } from '@src/ui';
import clsx from 'clsx';
import Link from 'next/link';

import type { PropsWithChildren } from 'react';
import { black, neutralHighest } from '@src/support/colors';
import { DSBodyText } from '../typography';

export type BreadcrumbLink = {
  href?: string;
  isExternal?: boolean;
  title?: string;
};

type BreadcrumbsProps = {
  links: BreadcrumbLink[];
};

export const DSBreadcrumbs = ({ links }: BreadcrumbsProps) => (
  <>
    <div className="ds-breadcrumbs">
      {links.map(({ href, isExternal, title }, index) => {
        const isLastLink = links.length - 1 === index;
        const LinkComponent = ({ children }: PropsWithChildren<{}>) => {
          if (href)
            return isExternal ? (
              <a href={href}>
                <span className="external-link ds-body-text ds-body-text--small ds-breadcrumbs__link">
                  {title}
                </span>
              </a>
            ) : (
              <Link href={href} passHref legacyBehavior>
                <a>{children}</a>
              </Link>
            );
          else return <>{children}</>;
        };
        return (
          <div className="ds-breadcrumbs__container" key={href || index}>
            <LinkComponent>
              <DSBodyText
                small
                bold={isLastLink}
                className={clsx('ds-breadcrumbs__link', {
                  'ds-breadcrumbs__link--last': isLastLink,
                })}
              >
                {title}
              </DSBodyText>
            </LinkComponent>
            {!isLastLink && (
              <FontAwesomeIcon
                className="ds-breadcrumbs__divider"
                color={neutralHighest}
                icon={['fas', 'chevron-right']}
              />
            )}
          </div>
        );
      })}
      <style jsx>
        {`
          .ds-breadcrumbs {
            display: flex;
            align-items: center;
            gap: 0.5em;
          }
          :global(.ds-breadcrumbs__container) {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          :global(.ds-breadcrumbs__divider) {
            font-size: 0.5em;
          }

          :global(.ds-breadcrumbs__link) {
            color: ${neutralHighest};
          }

          :global(.ds-breadcrumbs__link:hover),
          :global(.ds-breadcrumbs__link--last) {
            color: ${black};
          }
        `}
      </style>
    </div>
  </>
);
