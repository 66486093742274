import { Button } from '@src/ui';

export function SignupButton({ source }: { source: string }) {
  return (
    <Button
      href={`/signup?source=${source}`}
      component="a"
      shape="rounded"
      color="primary"
      size="medium"
      style={{
        paddingLeft: '1.5em',
        paddingRight: '1.5em',
        paddingTop: '0.3125em',
        paddingBottom: '0.3125em',
      }}
    >
      Sign Up
    </Button>
  );
}
