import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { CollapsibleContext } from './collapsible-context';
import { boxShadow } from '@src/support/shadows';

type Props = {
  open?: boolean;
  onToggle: (open: boolean) => void;
  collapsible?: boolean;
  shadow?: boolean;
};

export const CollapsibleSection = ({
  open = true,
  onToggle,
  collapsible = true,
  children,
  shadow = true,
}: PropsWithChildren<Props>) => {
  const contextValues = {
    open,
    onToggle,
    collapsible,
  };
  return (
    <CollapsibleContext.Provider value={contextValues}>
      <div className={clsx('CollapsibleSection', { open })}>
        {children}
        <style jsx>{`
          .CollapsibleSection {
            width: 100%;
            box-shadow: ${shadow ? boxShadow : 'none'};
            border-radius: 0 0 0.25rem 0.25rem;
          }
        `}</style>
      </div>
    </CollapsibleContext.Provider>
  );
};
