import type { ParsedUrlQueryInput } from 'querystring';
import type { NextRouter } from 'next/router';
import type { GetStaticPropsContext } from 'next';
import type {
  Filter,
  JobsPageSpecialtyUnitFilter,
} from '@src/queries/jobs-page-content-query';
import { JOB_PAGE_TYPES, JOB_URL_TYPE, STATE_CODES } from '@src/constants';
import { titleCaseToKebabCase } from '@src/utils/presentation';
import { buildAsPath } from '@src/utils/routing';
import { flatten } from '@src/utils/conversion';

import type { SpecialtyUnit } from '@src/components/jobs-page';

interface SeoInput {
  jobType: JOB_URL_TYPE;
  role: string | null;
  unit: string | null;
  state: string | null;
  city: string | null;
}

const SeoInputs = {
  jobType: 'jobType',
  role: 'role',
  unit: 'unit',
  state: 'state',
  city: 'city',
};

type SeoInputKey = keyof typeof SeoInputs;

export interface NavigateParams {
  jobType: JOB_URL_TYPE;
  role?: string;
  specialtyUnit?: SpecialtyUnit;
  state?: string;
  city?: string;
  page?: number;
  shiftTypes?: string[];
}

export const humanizeJobType = (jobType: JOB_URL_TYPE) =>
  jobType
    .split('-')
    .map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join(' ');

const seoTemplates = {
  jobType: ({ jobType }: SeoInput) => {
    return `Find your dream ${humanizeJobType(
      jobType
    )} job with Trusted®. Highest Pay in the industry. Health insurance & 401(K) from day one. Apply today!`;
  },
  jobTypeRole: ({ role }: SeoInput) => {
    return `Find your dream travel ${role} job with Trusted®. Highest Pay in the industry. Health insurance & 401(K) from day one. Apply today!`;
  },
  jobTypeUnit: ({ jobType, unit }: SeoInput) => {
    return `Find your dream ${unit} ${humanizeJobType(
      jobType
    )} job with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeState: ({ jobType, state }: SeoInput) => {
    return `Find your dream ${humanizeJobType(
      jobType
    )} job in ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeStateCity: ({ jobType, state, city }: SeoInput) => {
    return `Find your dream ${humanizeJobType(
      jobType
    )} job in ${city}, ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeRoleState: ({ role, state }: SeoInput) => {
    return `Find your dream travel ${role} job in ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeRoleStateCity: ({ role, state, city }: SeoInput) => {
    return `Find your dream travel ${role} job in ${city}, ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeRoleUnit: ({ role, unit }: SeoInput) => {
    return `Find your dream ${unit} travel ${role} job with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeRoleUnitState: ({ unit, role, state }: SeoInput) => {
    return `Find your dream ${unit} travel ${role} job in ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeRoleUnitStateCity: ({ role, unit, state, city }: SeoInput) => {
    return `Find your dream ${unit} travel ${role} job in ${city}, ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeUnitState: ({ jobType, unit, state }: SeoInput) => {
    return `Find your dream ${unit} ${jobType} job in ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
  jobTypeUnitStateCity: ({ jobType, unit, state, city }: SeoInput) => {
    return `Find your dream ${unit} ${humanizeJobType(
      jobType
    )} job in ${city}, ${state} with Trusted®. Highest Pay in the industry. Apply today!`;
  },
};

type SeoTemplateKey = keyof typeof seoTemplates;

export function getSeoDescription(inputs: SeoInput): string {
  const seoTemplateKey = Object.keys(inputs)
    .filter(input => !!inputs[input as SeoInputKey])
    .map((input, i) =>
      i > 0 ? `${input.charAt(0).toUpperCase()}${input.slice(1)}` : input
    )
    .join('');

  const seoTemplate = seoTemplates[seoTemplateKey as SeoTemplateKey];

  if (seoTemplate) {
    return seoTemplate(inputs);
  } else {
    return 'Find your dream travel job with Trusted®. Highest Pay in the industry. Health insurance & 401(K) from day one. Apply today!';
  }
}

export function replaceEmptyTagElements(value: string) {
  return (value || '').replace(/<p><\/p>/g, '<p>&nbsp;</p>');
}

export const getStateCode = (stateSlug: string | undefined) =>
  (STATE_CODES[stateSlug?.toUpperCase() || ''] && stateSlug?.toUpperCase()) ||
  Object.keys(STATE_CODES).find(
    (stateCode: string) =>
      STATE_CODES[stateCode]?.toLowerCase().replace(/\s/g, '-') ===
      stateSlug?.toLowerCase()
  );

export const getStateName = (stateCode: string) => STATE_CODES[stateCode];

export function navigate(
  router: NextRouter,
  {
    role,
    specialtyUnit,
    state,
    city,
    page,
    shiftTypes,
    jobType,
  }: NavigateParams
) {
  let pathname = `/jobs/${jobType}`;
  const query: ParsedUrlQueryInput = {};

  if (typeof role === 'string' && !!role) {
    pathname += '/roles/[role]';
    query.role = role;
  }

  if (specialtyUnit) {
    const slug = specialtyUnit.slug.toLowerCase();
    if (
      specialtyUnit.__typename === 'TrustedJobUnit' ||
      specialtyUnit.__typename === 'ClinicalUnit'
    ) {
      pathname += '/units/[unit]';
      query.unit = slug;
    } else if (specialtyUnit.__typename === 'TrustedJobSpecialty') {
      pathname += '/specialties/[specialty]';
      query.specialty = slug;
    } else if (specialtyUnit.__typename === 'JobsSpecialty') {
      pathname += '/specialties-units/[specialtyUnit]';
      query.specialtyUnit = slug;
    }
  }

  if (typeof state === 'string' && !!state) {
    state = titleCaseToKebabCase(state);
    pathname += '/states/[state]';
    query.state = state;
  }
  if (typeof city === 'string' && !!city) {
    city = titleCaseToKebabCase(city);
    pathname += '/cities/[city]';
    query.city = city;
  }

  if (typeof page === 'number' && page !== 1) query.page = page;
  if (typeof shiftTypes === 'object' && !!shiftTypes)
    query.shiftTypes = shiftTypes.join(',');

  if (
    query.role !== router.query.role ||
    query.unit !== router.query.unit ||
    query.state !== router.query.state ||
    query.city !== router.query.city ||
    page !== router.query.page ||
    query.shiftTypes !== router.query.shiftTypes
  ) {
    router.push(buildAsPath(pathname, query));
  }
}

function isWashingtonDC(firstValue: string, secondValue: string) {
  const city = firstValue?.toLowerCase()?.replace(/\./g, '');
  const state = secondValue?.toLowerCase()?.replace(/\./g, '');
  if (
    state?.includes('dc') ||
    state?.includes('district of columbia') ||
    city?.includes('district of columbia') ||
    city?.includes('dc')
  ) {
    return true;
  }
  return false;
}

export const splitLocation = (locationString: string) => {
  // locationString could be "Oakland, CA" or "California".
  // In the first scenario firstValue will be Oakland and secondValue will be "CA".
  // For the second scenario firstValue will be "California" and will be used in the search.
  const address = locationString.split(',');
  if (address.length === 3) address.splice(1, address.length - 2);
  const [firstValue, secondValue] = address;

  // Edge case 1:1 mapping for DC
  if (isWashingtonDC(firstValue, secondValue))
    return { city: 'Washington', state: STATE_CODES['DC'] };

  if (secondValue) {
    return {
      city: firstValue?.trim(),
      state: STATE_CODES[secondValue.trim().toUpperCase()] || '',
    };
  } else {
    return {
      city: '',
      state:
        Object.values(STATE_CODES).find(
          key =>
            key.toUpperCase() ===
            firstValue.replace('State', '').trim().toUpperCase()
        ) ||
        STATE_CODES[firstValue.trim().toUpperCase()] ||
        '',
    };
  }
};

export const getCanonicalUrl = ({
  router,
  canonicalPath,
  jobUrlType = JOB_URL_TYPE.TravelNurse,
}: {
  router: NextRouter;
  canonicalPath?: string;
  jobUrlType?: JOB_URL_TYPE;
}) => {
  let url = `https://www.trustedhealth.com/jobs/${jobUrlType}`;

  const { city, role, specialty, specialtyUnit, state, unit } = router.query;

  if (role && !specialty && !specialtyUnit && !unit && !state && !city) {
    url += `/roles/${role}`;
  }

  if (specialty && canonicalPath) {
    url += canonicalPath;
  } else if (specialty) {
    url += `/specialties/${specialty}`;
  }

  if (specialtyUnit && canonicalPath) {
    url += canonicalPath;
  } else if (specialtyUnit) {
    url += `/specialties-units/${specialtyUnit}`;
  }

  if (unit && canonicalPath) {
    url += canonicalPath;
  } else if (unit) {
    url += `/units/${unit}`;
  }

  if (state) {
    const stateCode = flatten(state)?.toUpperCase();
    const stateName =
      (stateCode ? STATE_CODES[stateCode] : flatten(state)) || flatten(state);
    url += `/states/${stateName?.toLowerCase().split(/\s/).join('-')}`;
  }

  if (city) {
    url += `/cities/${city}`;
  }

  return url;
};

const getLocationString = (context: GetStaticPropsContext) => {
  const stateSlug = flatten(context.params?.state);
  const citySlug = flatten(context.params?.city);

  const stateCode = getStateCode(flatten(stateSlug));

  if (citySlug && stateCode) {
    const city = citySlug
      ? citySlug
          .split('-')
          .map(word => word[0].toUpperCase() + word.substring(1))
          .join(' ')
      : '';

    return `${city}, ${stateCode}`;
  }

  if (!citySlug && stateSlug) {
    return getStateName(stateCode || flatten(stateSlug)?.toUpperCase() || '');
  }

  return '';
};

export const jobPageContextDetails = (context: GetStaticPropsContext) => {
  const jobTypeParam = flatten(context.params?.jobType) as JOB_URL_TYPE;
  const jobType = JOB_PAGE_TYPES[jobTypeParam];
  const specialtySlug = flatten(context.params?.specialty) as string;
  const specialtyUnit = flatten(context.params?.specialtyUnit) as string;
  const unitSlug = flatten(context.params?.unit) as string;
  const locationString = getLocationString(context);
  const roleSlug = flatten(context.params?.role) as string;

  return {
    jobTypeParam,
    jobType,
    locationString,
    roleSlug,
    specialtySlug,
    specialtyUnit,
    unitSlug,
  };
};

export const getJobsCount = (
  specialtyUnit: JobsPageSpecialtyUnitFilter | Filter,
  jobType: JOB_URL_TYPE | undefined | ''
) => {
  switch (jobType) {
    case JOB_URL_TYPE.TravelAllied:
      return specialtyUnit.alliedJobsCount || 0;
    case JOB_URL_TYPE.TravelNurse:
      return specialtyUnit.nursingJobsCount || 0;
    default:
      return 0;
  }
};
